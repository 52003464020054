<template>
  <div class="container">
    <center>
      <h1>Data Deletion Instructions</h1>
      <p>If you wish to delete your account data, please contact us at: <a href="mailto:ivalleyinc@gmail.com">ivalleyinc@gmail.com</a></p>
    </center>
  </div>
</template>

<script>
export default {
  name: 'DataDeletionPage'
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Full viewport height */
  text-align: center; /* Center the text */
}
</style>
